<template>
  <b-card header="Edit User Roles">
    <b-form>
      <roles-table
        :selected-roles.sync="selectedRoles"
        :entity-id="$store.getters['mainEntity/getEntityId']"
        @assignRole="assignUserRole"
        @revokeRole="revokeUserRole"
      />
      <back />
    </b-form>
  </b-card>
</template>
<script>
import RolesTable from '@/common/components/Permissions/RolesTable.vue'
import Back from '@/common/components/common/FormInputs/Back.vue'
import UserRoles from '@/common/compositions/permissions/userRoles'

export default {
  name: 'EditUserRole',
  components: {
    RolesTable,
    Back,
  },
  data() {
    return {
      selectedRoles: [],
    }
  },
  setup() {
    const { getUserRolesInEntity, assignUserRole, revokeUserRole } = UserRoles()

    return {
      getUserRolesInEntity,
      assignUserRole,
      revokeUserRole,
    }
  },
  created() {
    this.getUserRoles()
  },
  methods: {
    getUserRoles() {
      this.getUserRolesInEntity(this.$route.params.username, this.$store.getters['mainEntity/getEntityId']).then(roles => {
        this.selectedRoles = roles.map(role => role.name)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
