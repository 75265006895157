import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
import handleAlerts from '../common/handleAlerts'

const { successfulOperationAlert } = handleAlerts()

export default function userRoles() {
  const getUserRolesInEntity = (username, entityId) => axiosInstances.portalUsers.get(`v1/entity/member/role/${username}/${entityId}`).then(res => res.data.data || [])

  const assignUserRole = (roleId, entityId) => {
    axiosInstances.portalUsers.post(`v1/user-assign-roles/${router.currentRoute.params.username}`, {
      entityId,
      roles: roleId,
    }).then(() => {
      successfulOperationAlert('User is assigned to role successfully')
    })
  }

  const revokeUserRole = (roleId, entityId) => {
    axiosInstances.portalUsers.post(`v1/user-revoke-roles/${router.currentRoute.params.username}`, {
      entityId,
      roles: roleId,
    }).then(() => {
      successfulOperationAlert('User role is revoked successfully')
    })
  }

  return {
    getUserRolesInEntity,
    assignUserRole,
    revokeUserRole,
  }
}
