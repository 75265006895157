<template>
  <div>
    <h4 class="mb-1">
      User: {{ $route.params.username }}
    </h4>

    <b-form-checkbox-group
      :checked="selectedRoles"
      @change="onSelectRole"
    >
      <b-table
        :items="entityRolesProvider"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
      >
        <template #cell(displayName)="{item}">
          <span class="text-capitalize">{{ item.displayName }}</span>
        </template>
        <template #cell(selected)="{ item }">
          <b-form-checkbox
            :id="`${item.name}-checkbox`"
            :value="item.name"
          />
        </template>
      </b-table>
    </b-form-checkbox-group>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>

<script>
import Roles from '@/common/compositions/permissions/roles'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '../common/Table/Pagination.vue'

export default {
  name: 'PermissionsTable',
  components: { Pagination },
  props: {
    selectedRoles: { type: Array, default: () => [] },
    entityId: { type: [Number, String], default: '' },
  },
  data() {
    return {
      fields: ['selected', { key: 'displayName', label: 'title' }, 'description'],
    }
  },
  setup() {
    const { pagination } = paginationData()
    const { getEntityRoles } = Roles()
    return { getEntityRoles, pagination }
  },
  methods: {
    entityRolesProvider() {
      return this.getEntityRoles(this.entityId, { page: this.pagination.currentPage }).then(res => {
        this.pagination.totalRows = res.pagination.total
        return res.data || []
      })
    },
    onSelectRole(newRoles) {
      const revokedRoles = this.getDifferenceBetweenArrays(this.selectedRoles, newRoles)
      this.$emit('update:selectedRoles', newRoles)

      if (revokedRoles.length) {
        this.$emit('revokeRole', revokedRoles, this.entityId)
      } else {
        const lastRoleSelected = [newRoles.at(-1)]
        this.$emit('assignRole', lastRoleSelected, this.entityId)
      }
    },
    getDifferenceBetweenArrays(oldRoles, newRoles) {
      return oldRoles.filter(role => !newRoles.includes(role))
    },
  },
}
</script>
<style lang="scss">
.pointer{
  cursor:pointer
}
.permissions-checkbox{
  .custom-control:not(:last-child) {
    margin-bottom: 1em;
 }
}
</style>
